.MuiListItem-root .MuiListItemIcon-root {
  justify-content: center;
  text-align: justify;
  min-width: 16px;
  margin: 0px 8px;
}

.MuiListItem-root .MuiListItemText-root {
  text-align: justify;
}
