.MuiDialog-root.MuiModal-root {
  .MuiDialog-container {
    .MuiPaper-root.MuiDialog-paper {
      @media (max-width: 1024px) {
        margin: 0;
        max-height: 100%;
        border-radius: 0;
      }

      .MuiDialogTitle-root {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      }

      .MuiDialogContent-root {
        margin: 6px 0;
      }

      .MuiDialogActions-root {
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}
