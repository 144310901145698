.sessionsTable {
  thead {
    background-color: #1976d2;

    tr {
      th {
        color: white;
        font-size: 1.1em;
        font-weight: bold;
      }
    }
  }

  tbody {
    tr {
      &.divider {
        background-color: #e9e9e9;

        td {
          font-size: 1.1em;
          font-weight: bold;
        }
      }

      &.even {
        background-color: #f9f9f9;
      }

      &.odd {
        background-color: #fff;
      }
    }
  }
}
