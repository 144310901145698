.month-gameMaster {
  background-color: rgb(156, 39, 176) !important;
}

.month-pending {
  background-color: rgb(237, 108, 2) !important;
}

.month-validated {
  background-color: rgb(46, 125, 50) !important;
}

.week-gameMaster {
  background-color: rgb(156, 39, 176) !important;
}

.week-pending {
  background-color: rgb(237, 108, 2) !important;
}

.week-validated {
  background-color: rgb(46, 125, 50) !important;
}

.agenda-gameMaster {
  background-color: rgb(248, 239, 249) !important;
}

.agenda-pending {
  background-color: rgb(255, 244, 229) !important;
}

.agenda-validated {
  background-color: rgb(237, 247, 237) !important;
}
