.loaderContainer {
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;

  &.hidden {
    display: none;
  }
}

.loader {
  animation: l6 2s infinite linear;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 10px solid;
  box-sizing: border-box;
  color: #000;
  position: relative;
  width: 60px;
}

.loader:before {
  background:
    linear-gradient(currentColor 0 0) top/100% 30%,
    linear-gradient(currentColor 0 0) top/50% 100%;
  background-repeat: no-repeat;
  border-radius: 5px 5px 0 0;
  content: "";
  height: 20px;
  inset: auto calc(50% - 10px) 100%;
  position: absolute;
}

.loader:after {
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background:
    radial-gradient(farthest-side, currentColor 94%, #0000) left,
    radial-gradient(farthest-side, currentColor 94%, #0000) right;
  content: "";
  height: 15px;
  inset: -8px -10px auto;
  position: absolute;
}

@keyframes l6 {
  0% {
    background: conic-gradient(#77a4bd 0, #0000 0)
  }

  12.5% {
    background: conic-gradient(#77a4bd 45deg, #0000 46deg)
  }

  25% {
    background: conic-gradient(#77a4bd 90deg, #0000 91deg)
  }

  37.5% {
    background: conic-gradient(#77a4bd 135deg, #0000 136deg)
  }

  50% {
    background: conic-gradient(#77a4bd 180deg, #0000 181deg)
  }

  62.5% {
    background: conic-gradient(#77a4bd 225deg, #0000 226deg)
  }

  75% {
    background: conic-gradient(#77a4bd 270deg, #0000 271deg)
  }

  87.5% {
    background: conic-gradient(#77a4bd 315deg, #0000 316deg)
  }

  100% {
    background: conic-gradient(#77a4bd 360deg, #0000 360deg)
  }
}
