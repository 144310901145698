/* Webfont: Luciole-Regular */
@font-face {
  font-family: 'Luciole';
  src: url('fonts/Luciole-Regular/Luciole-Regular.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Luciole-Regular/Luciole-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Luciole-Regular/Luciole-Regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Luciole-Regular/Luciole-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/Luciole-Regular/Luciole-Regular.svg#Luciole-Regular') format('svg');
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Luciole-Bold */
@font-face {
  font-family: 'Luciole';
  src: url('fonts/Luciole-Bold/Luciole-Bold.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Luciole-Bold/Luciole-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Luciole-Bold/Luciole-Bold.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Luciole-Bold/Luciole-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/Luciole-Bold/Luciole-Bold.svg#Luciole-Bold') format('svg');
  /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Luciole-Italic */
@font-face {
  font-family: 'Luciole';
  src: url('fonts/Luciole-Italic/Luciole-Italic.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Luciole-Italic/Luciole-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Luciole-Italic/Luciole-Italic.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Luciole-Italic/Luciole-Italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/Luciole-Italic/Luciole-Italic.svg#Luciole-Italic') format('svg');
  /* Legacy iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Luciole-BoldItalic */
@font-face {
  font-family: 'Luciole';
  src: url('fonts/Luciole-BoldItalic/Luciole-BoldItalic.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Luciole-BoldItalic/Luciole-BoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Luciole-BoldItalic/Luciole-BoldItalic.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Luciole-BoldItalic/Luciole-BoldItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/Luciole-BoldItalic/Luciole-BoldItalic.svg#Luciole-BoldItalic') format('svg');
  /* Legacy iOS */
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
